<script setup lang="ts">
import Header from "@/components/organisms/Header";
import { getLanguageName } from "@shopware-pwa/helpers-next";
import { useStoreApi } from "@/composables/useStoreApi";
import { useMegaMenuExtension } from "@/composables/useMegaMenuExtension";
import CheckoutSideCart from "@/components/checkout/CheckoutSideCart.vue";
import WishlistNotification from "@/components/atoms/WishlistNotification/WishlistNotification.vue";
import { useFilteredCartItems } from "@/composables/useFilteredCartItems";

import { useCart } from "@/composables/useCart";

const { filteredCartItems } = useFilteredCartItems();

const { getMegaMenuStructure } = useMegaMenuExtension();

const { isLoggedIn } = useUser();

const { getNavigationMenu } = useStoreApi();

const navigationElements = await getNavigationMenu({
  activeId: "main-navigation",
});
const megaMenuStructure = await getMegaMenuStructure(navigationElements as any);

const { languages, changeLanguage, getLanguageCodeFromId } =
  useInternationalization();
const switchLocalePath = useSwitchLocalePath();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { locale } = useI18n();

const sidebarController = useSideMenuModal();

const { count } = useWishlist();

const languageOptions = languages.value?.map((language) => {
  return {
    label: getLanguageName(language),
    value: language.id as string,
    selected: getLanguageCodeFromId(language.id!) === locale.value,
  };
});

const onLanguageChange = async (languageId: string) => {
  const code = getLanguageCodeFromId(languageId);

  const path = switchLocalePath(code);

  await changeLanguage(languageId);
  location.replace(path);
};

const computedAccountLink = computed(() => {
  return isLoggedIn.value
    ? ((formatLink("/account") || "/account") as string)
    : ((formatLink("/login") || "/login") as string);
});
const computedWishlistLink = computed(() => {
  return (formatLink("/wishlist") || "/wishlist") as string;
});
</script>

<template>
  <Header
    :language-options="languageOptions"
    :cart-items-amount="filteredCartItems.length"
    :home-link="formatLink('/') as string"
    :structured-mega-menu="megaMenuStructure"
    :account-link="computedAccountLink"
    :wishlist-link="computedWishlistLink"
    :count="count"
    @language-change="onLanguageChange"
    @open-side-cart="sidebarController.open({ closeTimeout: 0 })"
  />
  <CheckoutSideCart />
  <WishlistNotification :count="count" />
</template>
