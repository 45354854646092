import type { Schemas } from "#shopware";

export const getFormattedLinkFromSeoUrl = (seoUrl: Schemas["SeoUrl"]) => {
  if (!seoUrl || !seoUrl?.pathInfo) return null;

  const safeLink = seoUrl?.seoPathInfo || seoUrl?.pathInfo;
  const normalisedSafeLink = safeLink.startsWith("/")
    ? safeLink
    : `/${safeLink}`;

  return {
    path: normalisedSafeLink,
    state: {
      routeName: seoUrl?.routeName,
      foreignKey: seoUrl?.foreignKey,
    },
  };
};
