import { useStoreApi } from "@/composables/useStoreApi";
import type { UseNavigationReturn } from "@shopware-pwa/composables-next";
import { htmlParser } from "@/utils/htmlParser";
import type { Media } from "@shopware-pwa/types";
import {
  getCategoryRoute,
  getTranslatedProperty,
} from "@shopware-pwa/helpers-next";

import { megaMenuCategoryTypes } from "@/constants/megaMenuCategoryTypes";
import { getFormattedLinkFromSeoUrl } from "@/utils/seoUrlToFormattedLink";

type NavigationElements = UseNavigationReturn["navigationElements"]["value"];

type NavigationElement =
  NonNullable<NavigationElements> extends (infer U)[] ? U : never;

type CategoryCSSStyles = {
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: number;
  fontColor?: string;
  fontSize?: number;
  fontWeight?: number;
  markAsMainCategoryLink?: boolean;
};

type MegaMenuCustomFields = {
  megaMenuBannerImage?: string;
  megaMenuHtml?: string;
  megaMenuConfig?: {
    default?: CategoryCSSStyles;
    hover?: CategoryCSSStyles;
    active?: CategoryCSSStyles;
  };
  megaMenuColumns?: {
    categories: string[];
    size: number;
  }[];
};

type CommonCategory<T> = {
  details: T & {
    config?: {
      default?: CategoryCSSStyles;
      hover?: CategoryCSSStyles;
      active?: CategoryCSSStyles;
    };
  };
  id: string;
  type: string;
};

export type PageCategory = CommonCategory<{
  title: string;
  link: ReturnType<typeof getCategoryRoute>;
  type: "page";
}>;

export type LinkCategory = CommonCategory<{
  title: string;
  link: ReturnType<typeof getCategoryRoute>;
  target?: "_blank";
  type: "link";
}>;

export type HTMLCategory = CommonCategory<{
  parsed: {
    tag: string;
    value: string;
    attributes: string;
    parsedAttributes: Record<string, string>;
  }[];
  type: "html";
}>;

export type BannerCategory = {
  details: Media;
  id: string;
  type: "banner";
};

export type NavigationElementWithMegaMenuCandidate = NavigationElement & {
  customFields?: MegaMenuCustomFields;
  translated?: Record<string, unknown> & {
    customFields: MegaMenuCustomFields;
  };
};
export type StructuredMegaMenuCategory = ReturnType<
  ReturnType<typeof useMegaMenuExtension>["parentCategoryResolver"]
>;

export type StructuredMegaMenu = StructuredMegaMenuCategory[];

export const useMegaMenuExtension = () => {
  const { getMediaByMediaIds, getSeoUrls } = useStoreApi();

  const localePath = useLocalePath();
  const { formatLink } = useInternationalization(localePath);

  const asyncPromises: Promise<any>[] = [];
  const collectedInternalLinkIds: string[] = [];
  const collectedMediaIds: string[] = [];

  const collectedParentInternalLinkIds: Record<PropertyKey, string | object>[] =
    [];

  const prepareLinkDetailsToFetchLater = (
    category: NavigationElementWithMegaMenuCandidate,
  ) => {
    let link;

    const safeLinkType = category?.translated?.linkType || category?.linkType;
    const safeInternalLink =
      category?.translated?.internalLink || category?.internalLink;
    const safeExternalLink =
      category?.translated?.externalLink || category?.externalLink;

    const isInternalLink = safeLinkType !== "external" && safeInternalLink;
    const isExternalLink = safeLinkType === "external" && safeExternalLink;

    if (isInternalLink) {
      collectedInternalLinkIds.push(safeInternalLink);

      link = {
        type: "internal",
        value: safeInternalLink,
      };
    }

    if (isExternalLink) {
      link = category?.externalLink;
    }

    return link;
  };

  const pageCategoryResolver = (
    category: NavigationElementWithMegaMenuCandidate,
  ) => {
    return {
      title: getTranslatedProperty(category, "name"),
      link: formatLink(getCategoryRoute(category as any)),
      config: category.customFields?.megaMenuConfig,
    };
  };

  const htmlCategoryResolver = (
    category: NavigationElementWithMegaMenuCandidate,
  ) => {
    const megaMenuHtml =
      category.customFields?.megaMenuHtml ||
      category?.translated?.customFields?.megaMenuHtml;
    if (!megaMenuHtml) return {};

    const config =
      category.customFields?.megaMenuConfig ||
      category?.translated?.customFields?.megaMenuConfig;

    return {
      parsed: htmlParser(megaMenuHtml),
      config,
    };
  };

  const bannerCategoryResolver = (
    category: NavigationElementWithMegaMenuCandidate,
  ) => {
    const megaMenuBannerImage =
      category?.translated?.customFields?.megaMenuBannerImage ||
      category.customFields?.megaMenuBannerImage;

    if (!megaMenuBannerImage) {
      return {
        banner: null,
        link: null,
      };
    }
    collectedMediaIds.push(megaMenuBannerImage);
    const linkPromise = prepareLinkDetailsToFetchLater(category);

    return {
      banner: null,
      link: linkPromise,
    };
  };

  const linkCategoryResolver = (
    category: NavigationElementWithMegaMenuCandidate,
  ) => {
    const isNewTab = category?.linkNewTab;

    return {
      link: prepareLinkDetailsToFetchLater(category),
      title: getTranslatedProperty(category, "name"),
      config: category.customFields?.megaMenuConfig,
      target: isNewTab ? "_blank" : "",
    };
  };

  const megaMenuColumnResolver = (
    menuColumn: NavigationElementWithMegaMenuCandidate[],
  ) => {
    return menuColumn?.map((category) => {
      if (!category) return undefined;
      switch (category?.type) {
        case megaMenuCategoryTypes["PAGE"]:
          return {
            details: pageCategoryResolver(category),
            type: megaMenuCategoryTypes["PAGE"],
            id: category.id,
          };
        case megaMenuCategoryTypes["HTML"]:
          return {
            details: htmlCategoryResolver(category),
            type: megaMenuCategoryTypes["HTML"],
            id: category.id,
          };
        case megaMenuCategoryTypes["BANNER"]:
          return {
            details: bannerCategoryResolver(category),
            type: megaMenuCategoryTypes["BANNER"],
            id: category.id,
          };
        case megaMenuCategoryTypes["LINK"]:
          return {
            details: linkCategoryResolver(category),
            type: megaMenuCategoryTypes["LINK"],
            id: category.id,
          };

        default:
          console.error(
            `Unknown category type to parse in MEGA MENU: ${category?.type}`,
          );
          return {};
      }
    });
  };

  const parentCategoryResolver = (
    parentCategory: NavigationElementWithMegaMenuCandidate,
  ) => {
    const megaMenuColumns =
      parentCategory?.translated?.customFields?.megaMenuColumns ||
      parentCategory?.customFields?.megaMenuColumns;

    const parentCategoryObj = {
      link: formatLink(getCategoryRoute(parentCategory)),
      name: getTranslatedProperty(parentCategory, "name"),
      id: parentCategory.id,
    };

    if (
      parentCategory.type === megaMenuCategoryTypes["LINK"] &&
      parentCategory.internalLink
    ) {
      collectedInternalLinkIds.push(parentCategory?.internalLink);

      collectedParentInternalLinkIds.push({
        categoryRef: parentCategoryObj,
        value: parentCategory?.internalLink,
      });
    }

    return {
      category: parentCategoryObj,
      children: megaMenuColumns?.map((categoryColumn) => {
        const enrichedCategories = categoryColumn.categories
          ?.map((iteratedCategoryId) => {
            if (!iteratedCategoryId) return undefined;
            return parentCategory.children.find((iteratedCategory) => {
              return iteratedCategory.id === iteratedCategoryId;
            });
          })
          ?.filter(Boolean);

        return megaMenuColumnResolver(enrichedCategories);
      }),
    };
  };

  const getMegaMenuStructure = async (
    navigationElements: NavigationElementWithMegaMenuCandidate[],
  ) => {
    if (!Array.isArray(navigationElements) || navigationElements?.length === 0)
      return [];

    const enrichedCategoriesWithPromises = navigationElements?.map(
      parentCategoryResolver,
    );

    const medias = await getMediaByMediaIds(collectedMediaIds);
    const seoUrls = await getSeoUrls(collectedInternalLinkIds);

    const linksDictionary = seoUrls?.elements?.reduce((acc, seoUrl) => {
      acc[seoUrl.foreignKey] = seoUrl;
      return acc;
    }, {});

    let seoUrlIdx = 0;
    let mediaIdx = 0;

    enrichedCategoriesWithPromises.forEach((category) => {
      category?.children?.forEach((column) => {
        column?.forEach((rowColumn) => {
          if (rowColumn?.type === megaMenuCategoryTypes["BANNER"]) {
            rowColumn.details.banner = medias?.[mediaIdx];
            mediaIdx++;

            if (rowColumn?.details?.link?.type === "internal") {
              rowColumn.details.link = getFormattedLinkFromSeoUrl(
                linksDictionary[rowColumn?.details?.link?.value],
              );
              seoUrlIdx++;
            }
          }

          if (rowColumn?.type === megaMenuCategoryTypes["LINK"]) {
            if (rowColumn?.details?.link?.type === "internal") {
              rowColumn.details.link = getFormattedLinkFromSeoUrl(
                linksDictionary[rowColumn?.details?.link?.value],
              );
              seoUrlIdx++;
            }
          }
        });
      });
    });

    for (const parentCategoryDetail of collectedParentInternalLinkIds) {
      const categoryId = parentCategoryDetail.value;

      const seoUrl = linksDictionary[categoryId];

      const readableSeoUrl = getFormattedLinkFromSeoUrl(seoUrl);
      if (readableSeoUrl) {
        parentCategoryDetail.categoryRef.link = readableSeoUrl;
      }
    }

    return enrichedCategoriesWithPromises;
  };

  return {
    getMegaMenuStructure,
    parentCategoryResolver,
  };
};
